import React, { Component } from 'react'
import { connect as reduxConnect } from 'react-redux'

import OrderActions from 'client/redux/actions/OrderActions'
import { isDayDisabled, isTimeslotDisabled, isDepotDisabled } from 'client/utils/OpeningTimes'

import LocationDate from './LocationDate'

/**
 * Specify order pickup and return time and location.
 *
 * @example
 *   <OrderPickupReturn order_id={1} />
 */

/* eslint-disable react/jsx-no-bind */
class OrderPickupReturn extends Component {
  static displayName = 'OrderPickupReturn'

  static propTypes = {}

  constructor (props) {
    super(props)

    this.state = {
      pickupAt: moment(props.order.pickup_at),
      pickupDepotId: props.order.pickup_depot_id,
      returnAt: moment(props.order.return_at),
      returnDepotId: props.order.return_depot_id,
      isSubmitting: false
    }
  }

  isSubmitting () {
    return this.state.isSubmitting
  }

  handleChange (values) {
    const oldState = { ...this.state }

    if (this.props.onChange) {
      this.setState(values, () => {
        this.props.onChange({
          pickup_at: this.state.pickupAt.format(),
          pickup_depot_id: this.state.pickupDepotId,
          return_at: this.state.returnAt.format(),
          return_depot_id: this.state.returnDepotId
        })
      })
    } else {
      values.isSubmitting = true
      this.setState(values, () => {
        OrderActions.update({
          id: this.props.order_id,
          attributes: {
            pickup_at: this.state.pickupAt.format(),
            pickup_depot_id: this.state.pickupDepotId,
            return_at: this.state.returnAt.format(),
            return_depot_id: this.state.returnDepotId
          },
          apiVersion: 2
        }).then(() => {
          this.setState({ isSubmitting: false })
        }).catch(() => {
          this.setState(oldState)
        })
      })
    }
  }

  handleChangePickup = (values) => {
    const newState = {}

    if (values.datetime) {
      newState.pickupAt = values.datetime

      if (newState.pickupAt >= this.state.returnAt) {
        // New pickupAt is later than the current returnAt, allow this but select a new returnAt
        newState.returnAt = newState.pickupAt.clone().add(this.state.returnAt - this.state.pickupAt)
      }

      if (this.props.order.handling_type === 'subscription') {
        newState.returnAt = newState.pickupAt.clone().add(2, 'years')
      }
    }

    if (values.depot) {
      const newPickupDepotId = values.depot.id
      newState.pickupDepotId = newPickupDepotId

      if (this.props.order.handling_type === 'subscription') {
        newState.returnDepotId = newPickupDepotId
      }
    }

    this.handleChange(newState)
  }

  handleChangeReturn = (values) => {
    const newState = {}

    if (values.datetime) {
      newState.returnAt = values.datetime
    }

    if (values.depot) {
      newState.returnDepotId = values.depot.id
    }

    this.handleChange(newState)
  }

  isDayDisabled (type) {
    return (day) => this.isSubmitting() || isDayDisabled({
      day,
      type,
      openingTimes: this.props.openingTimes,
      depots: this.props.depots,
      ...this.state
    })
  }

  isTimeslotDisabled (type) {
    return (timeslot) => this.isSubmitting() || isTimeslotDisabled({
      timeslot,
      type,
      openingTimes: this.props.openingTimes,
      depots: this.props.depots,
      ...this.state
    })
  }

  isDepotDisabled (type) {
    return (depot) => this.isSubmitting() || isDepotDisabled({
      depot,
      type,
      openingTimes: this.props.openingTimes,
      ...this.state
    })
  }

  getTimeslots (type) {
    const { openingTimes, timeslots } = this.props
    const { pickupAt, returnAt, pickupDepotId, returnDepotId } = this.state

    const time = type === 'pickup' ? pickupAt : returnAt
    const depotId = type === 'pickup' ? pickupDepotId : returnDepotId

    const weekday = moment(time).day()
    const openingTime = openingTimes.getFirst({ weekday: weekday, depot_id: depotId })

    if (openingTime) {
      return timeslots.getAll({ opening_time_id: openingTime.id })
    }

    // Return empty collection
    return timeslots.getAll({ id: 'does-not-exist' })
  }


  render () {
    const { depots, icon, disable_pickup, disable_return, order } = this.props
    const { pickupAt, returnAt } = this.state
    const pickupDepot = depots.getById(this.state.pickupDepotId)
    const returnDepot = depots.getById(this.state.returnDepotId)
    const range = { from: pickupAt.toDate(), to: returnAt.toDate() }
    const pickerOrientation = this.props.pickerOrientation || 'right'

    const pickupDepots = depots.filter(depot => depot.domain_id === order.domain_id).toArray()
    const otherDepots = depots.filter(depot => depot.domain_id !== order.domain_id).toArray()
    const returnDepots = pickupDepots.concat(otherDepots)

    return (
      <div className="locations-dates locations-dates-wrapper">
        <LocationDate
          datetime={pickupAt}
          depot={pickupDepot}
          depots={pickupDepots}
          timeslots={this.getTimeslots('pickup')}
          type="pickup"
          disabled={disable_pickup}
          onChange={this.handleChangePickup}
          isDayDisabled={this.isDayDisabled.bind(this)}
          isTimeslotDisabled={this.isTimeslotDisabled.bind(this)}
          isDepotDisabled={this.isDepotDisabled.bind(this)}
          isSubmitting={this.isSubmitting()}
          range={range}
          pickerOrientation={pickerOrientation}
        />

        <i className={`fa fa-${icon || 'arrow-down'} locations-dates__icon`} />

        <LocationDate
          datetime={returnAt}
          depot={returnDepot}
          depots={returnDepots}
          timeslots={this.getTimeslots('return')}
          type="return"
          disabled={disable_return}
          onChange={this.handleChangeReturn}
          isDayDisabled={this.isDayDisabled.bind(this)}
          isTimeslotDisabled={this.isTimeslotDisabled.bind(this)}
          isDepotDisabled={this.isDepotDisabled.bind(this)}
          isSubmitting={this.isSubmitting()}
          range={range}
          pickerOrientation={pickerOrientation}
        />
      </div>
    )
  }
}
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, props) => {
  return {
    order: state.orm.orders.getById(props.order_id),
    timeslots: state.orm.timeslots,
    depots: state.orm.depots,
    openingTimes: state.orm.opening_times
  }
}

export default reduxConnect(mapStateToProps)(OrderPickupReturn)
